/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/

.fidoc-mat-spinner {
    margin: 10% auto 10px auto;
    // circle {
    //   stroke: #c94b97;
    // }
}

.dropzone {
    margin: 4px;
    border: 2px dashed gray;
}

.contentzone {
    margin: 8px;
}

.instructions-prompt {
    background-color: #FFFAAA !important;
    height: 300px;
    width: 100% !important;
    padding: 10px !important;
}

.tool-menu.mat-mdc-menu-panel {
    max-width: unset !important;
    min-width: unset !important;
}

.dialog-menu {
    z-index: 999999 !important;
}